  <form [formGroup]="BossForm" (ngSubmit)="submit(BossForm)">
      <mat-card class="shipping-card">
  
          <mat-card-header>
              <mat-card-title>超级自选系统</mat-card-title>
          </mat-card-header>
          <mat-card-actions>
  
              <div class="row">
                  <div class="col">
                    <mat-radio-group formControlName="flag" >
                      <mat-radio-button value='1' >查询自选</mat-radio-button>
     <!--               <mat-radio-button value='2'>新增自选</mat-radio-button>
                      <mat-radio-button value='3'>删除自选</mat-radio-button>  -->
                      <button mat-raised-button color="primary" type="submit">提交 </button>
                    </mat-radio-group>
                  </div>
                </div>
  
          </mat-card-actions>
          <mat-card-content>
  
              <div class="row">
                  <div class="col">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="币名" formControlName="symbolID">
                      </mat-form-field>
                  </div>
              </div>
  
  
  
          </mat-card-content>
      </mat-card>
      <mat-divider></mat-divider>
      <div class="mat-elevation-z8">   <!--历史交易记录-->
          <table mat-table [dataSource]="dataSource" class="full-width-table" matSort aria-label="Elements">
            <!-- Id Column -->
            <ng-container matColumnDef="symbolID">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="expr-table-header">主角</th>
              <td mat-cell *matCellDef="let row" class="normalCust" style ="white-space: nowrap">{{row.symbolID}}</td>
            </ng-container>
            
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        <mat-paginator  [length]="total" [pageIndex]="0" [pageSize]="100" [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons>  </mat-paginator> 
        </div>
  </form>