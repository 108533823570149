import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-personal-disk',
  templateUrl: './personal-disk.component.html',
  styleUrls: ['./personal-disk.component.css']
})
export class PersonalDiskComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
