<form [formGroup]="loginForm" (ngSubmit)="submit(loginForm)">
    <mat-card class="shipping-card">
        <div [hidden]="status">
            <mat-card-header>
                <mat-card-title>管理员登录系统</mat-card-title>
            </mat-card-header>

            <mat-card-content>
                <div class="row">
                    <div class="col">
                        <mat-form-field class="full-width">
                            <input matInput placeholder="管理员账号" formControlName="profile">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <mat-form-field class="full-width">
                            <input type="password" matInput placeholder="管理员密码" formControlName="password">
                        </mat-form-field>
                    </div>
                </div>
            </mat-card-content>
        </div>
        <mat-card-actions>
            <div [hidden]="status">
                <button mat-raised-button color="primary" type="submit" [disabled]="loginForm.invalid">登录
                </button>
            </div>

            <!---->
            <div *ngIf="status && flag!='A' && flag!='F' && flag!='E'">
                <button mat-raised-button color="warn" type="submit" (click)="flag='1'" [ngClass]="ColorClass1(flag)">查询币种异常</button>
                <button mat-raised-button color="warn"  (click)="flag='2'" [ngClass]="ColorClass2(flag)" [matMenuTriggerFor]="Enquiry" type="button">查询</button>
                <button mat-raised-button color="warn"  (click)="flag='3'" [ngClass]="ColorClass3(flag)" [matMenuTriggerFor]="Modify" type="button">修改</button>
                <button mat-raised-button color="warn" type="submit" (click)="flag='999'" class="OverColor">退出</button>
                <mat-menu #Enquiry="matMenu">
                    <button mat-menu-item type="submit" (click)="flag='4'" [ngClass]="ColorClass4(flag)">大盘评分查询</button>
                    <button mat-menu-item type="submit" (click)="flag='5'" [ngClass]="ColorClass5(flag)">自选币评分查询</button>
                    <button mat-menu-item type="submit" (click)="flag='6'" [ngClass]="ColorClass6(flag)">机器人历史卖单查询</button>
                </mat-menu>
                
                <mat-menu #Modify="matMenu">
                    <button mat-menu-item type="submit" (click)="flag='8'" [ngClass]="ColorClass8(flag)">机器人参数修改</button>
                    <button mat-menu-item type="submit" (click)="flag='9'" [ngClass]="ColorClass9(flag)" disabled>密码修改</button>
                </mat-menu>



            </div>
            <!---->




        </mat-card-actions>
    </mat-card>
    <mat-divider></mat-divider>
</form>
<div *ngIf="flag!='0' && flag!='999' && flag!=A_ && flag!=E_ && flag!=F_">
    <app-mointor></app-mointor>
</div>
<div *ngIf="flag==E_||flag==A_||flag==F_"> <!--代表管理员-->
    <app-super-admin></app-super-admin>
</div>