<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
    <button mat-icon-button disabled></button>
    <mat-icon class="type-icon" [attr.aria-label]="node.type + 'icon'">
      {{ node.type === 'file' ? 'description' : 'folder' }}
    </mat-icon>
    {{node.name}}
  </mat-tree-node>

  <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
    <button mat-icon-button matTreeNodeToggle
            [attr.aria-label]="'toggle ' + node.name">
      <mat-icon class="mat-icon-rtl-mirror">
        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
      </mat-icon>
    </button>
    <mat-icon class="type-icon" [attr.aria-label]="node.type + 'icon'">
      {{ node.type ==='file' ? 'description' : 'folder' }}
    </mat-icon>
    {{node.name}}
  </mat-tree-node>
</mat-tree>
