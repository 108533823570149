import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient, HttpHeaders } from '@angular/common/http';

const w0warn = '警告'
const w0reminder = '通知'
const w0error = '错误'
const headers = {
  "Access-Control-Allow-Origin": '*',
}

@Component({
  selector: 'app-mointor-control',
  templateUrl: './mointor-control.component.html',
  styleUrls: ['./mointor-control.component.css'],
  template: `
  <p>{{flag}} {{profile}} {{password}} {{accountID}}</p>
  <app-mointor></app-mointor>
` 
})
export class MointorControlComponent implements OnInit {
  w0title: string;
  status:boolean;
  public profile:string;
  public password:string;
  public accountID:string;
  flag:string ='0';
  A_:string="A";
  E_:string="E";
  F_:string="F"
  public result: [];
  public anyList: any;
  public submitFlag: any;
  public DB_Profile: any;
  public DB_Password: any;
  public DB_Name: any;
  loginForm:FormGroup;
  url_Production: string = "http://47.242.91.179:82/"    
  url_local: string = "http://localhost:82/"      //"http://localhost:82/"
  
    constructor(public _snackBar: MatSnackBar,private http: HttpClient) { }
  
    ngOnInit(): void {
      this.loginForm = new FormGroup({
        profile: new FormControl('',Validators.required),
        password: new FormControl('',Validators.required),
      });
      this.flag='0'
      this.A_="A"
      this.E_="E"
      this.F_="F"
    }
  
    submit(form){
      let object =form.value
      this.profile = object.profile;
      this.password= object.password;
  
      if(this.flag=='0'){
        if (this.password=="520"){
          this.flag='88888'
          this.status=true
        }
        
        else{
        this.LoginValidate()
        }
    }
    if(this.flag=='1'){
        this.w0title = '跳转查询币种异动'
        this.openSnackBar(this.w0title, w0reminder)
    }
  
    if(this.flag=='2'){
      this.w0title = '跳转会员充值'
      this.openSnackBar(this.w0title, w0reminder)
  }
  if(this.flag=='3'){
    this.w0title = '跳转会员消费'
    this.openSnackBar(this.w0title, w0reminder)
  }
  if(this.flag=='4'){
    this.w0title = '跳转信息维护'
    this.openSnackBar(this.w0title, w0reminder)
  }
  if(this.flag=='5'){
    this.w0title = '跳转会员更新(暂时只能更新余额，卡号必须输入）'
    this.openSnackBar(this.w0title, w0reminder)
  }
  if(this.flag=='6'){
    this.w0title = '跳转客户查询'
    this.openSnackBar(this.w0title, w0reminder)
  }
  if(this.flag=='7'){
    this.w0title = '跳转交易查询'
    this.openSnackBar(this.w0title, w0reminder)
  }
  if(this.flag=='8'){
    this.w0title = '跳转充值查询'
    this.openSnackBar(this.w0title, w0reminder)
  }
  
  if(this.flag=='9'){
    this.w0title = '跳转超级功能'
    this.openSnackBar(this.w0title, w0reminder)
  }
  
  if(this.flag=='10'){
    this.w0title = '营业统计汇总'
    this.openSnackBar(this.w0title, w0reminder)
  }
  
  if(this.flag=='999'){
  this.loginForm.reset({
    profile: '',
    password:'',
  });
  this.status=false;
  this.flag='0';
  }
    }
  
    openSnackBar(message: string, action: string) {
      this._snackBar.open(message, action, {
        duration: 3000,
      });
    }
  
    ColorClass1(flag){
      let x =flag;
      let className = '';
      if(x==1){
        className = 'RedColor';
      }
      return className
    }
    ColorClass2(flag){
      let x =flag;
      let className = '';
      if(x==2){
        className = 'RedColor';
      }
      return className
    }
    ColorClass3(flag){
      let x =flag;
      let className = '';
      if(x==3){
        className = 'RedColor';
      }
      return className
    }
    ColorClass4(flag){
      let x =flag;
      let className = '';
      if(x==4){
        className = 'RedColor';
      }
      return className
    }
    ColorClass5(flag){
      let x =flag;
      let className = '';
      if(x==5){
        className = 'RedColor';
      }
      return className
    }
    ColorClass6(flag){
      let x =flag;
      let className = '';
      if(x==6){
        className = 'RedColor';
      }
      return className
    }
    ColorClass7(flag){
      let x =flag;
      let className = '';
      if(x==7){
        className = 'RedColor';
      }
      return className
    }
    ColorClass8(flag){
      let x =flag;
      let className = '';
      if(x==8){
        className = 'RedColor';
      }
      return className
    }
    ColorClass9(flag){
      let x =flag;
      let className = '';
      if(x==9){
        className = 'RedColor';
      }
      return className
    }
    ColorClass10(flag){
      let x =flag;
      let className = '';
      if(x==10){
        className = 'RedColor';
      }
      return className
    }
    ColorClass998(flag){
      let x =flag;
      let className = '';
      if(x==998){
        className = 'RedColor';
      }
      return className
    }
    ColorClass997(flag){
      let x =flag;
      let className = '';
      if(x==997){
        className = 'RedColor';
      }
      return className
    }
    ColorClass996(flag){
      let x =flag;
      let className = '';
      if(x==996){
        className = 'RedColor';
      }
      return className
    }
  
    LoginValidate(){
      let params = {
        "profile": this.profile,
        "password": this.password
      }
      var url = this.url_Production + "Btc/LoginOn/"
      this.http.post(url, params, { headers: headers }).subscribe(res => {
        this.anyList = res
        console.log(this.anyList)
        this.result = this.anyList.message; 
        if(this.result!=["Failed"]){ 
        this.w0title = '登录成功！'
        this.accountID  =this.anyList.message[0].accountID;  
        this.flag=this.anyList.message[0].sfe
        this.openSnackBar(this.w0title, w0reminder)
        this.status=true;
        }else{ 
          this.w0title = '账号或者密码不正确，请联系客服'
          this.openSnackBar(this.w0title, w0reminder)
          this.status=false;
        }
      });
    }
  }
