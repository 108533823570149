import { Component, OnInit, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableModule } from "@angular/material/table";
import * as moment from 'moment'
import { MatSnackBar } from '@angular/material/snack-bar';
import { ifStmt } from '@angular/compiler/src/output/output_ast';
import { ITS_JUST_ANGULAR } from '@angular/core/src/r3_symbols';
import { isNull } from 'util';
import { Observable } from "rxjs";
import { delay } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { BehaviorSubject } from "rxjs";
import { tap } from "rxjs/operators";
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MointorControlComponent } from 'src/app/BTC/mointor-control/mointor-control.component'
export interface PeriodicElement {
  symbolID: number;
  functionID: string;
  score: string
  updatetime: string;
}

export interface TransactionElement {
  name: string;
  symbolID: string;
  amount: number
  volumn: string;
  openprice:string;
  stopprice:string;
  updatedate:string;
  udpatetime:string
}

export interface RankElement {
  symbolID: string;
  score: number
}
const headers = {
  "Access-Control-Allow-Origin": '*',
}
const w0warn = '警告'
const w0reminder = '通知'
const w0error = '错误'

@Component({
  selector: 'app-mointor',
  templateUrl: './mointor.component.html',
  styleUrls: ['./mointor.component.css'],
  template: `{{_parent.flag}},{{_parent.profile}},{{_parent.password}},{{_parent.accountID}}`,
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'ja-JP' },

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})

export class MointorComponent {
  public waterfall1:string
  public waterfall2:string
  public waterfall3:string
  public waterfall4:string
  public waterfall5:string
  public waterfall6:string
  public waterfall7:string
  public waterfall8:string
  public waterfall9:string
  public waterfall10:string
  itemList: []
  total: number = 0;
  custTotal: number = 0;
  item = [];
  custItem = [];
  public result: [];
  public anyList: any;
  public submitFlag: any;
  w0name: any;
  w0changer:any;
  w0changerU:any;
  w0changerX:any;
  w0amount: any;
  w0contractamount: any;
  w0contractamountX: any;
  addressForm: FormGroup
  w0mobile: string;
  w0cardNumber: string;
  w1name: string;
  w1mobile: string;
  w1cardNumber: number;
  w1availableMoney: number;
  w1symbolID:any;
  w1functionID:any;
  w1score:any;
  w1updatetime:any;
  w0date: string;
  w0item: string;
  w0itemPrice: string;
  w1itemPrice: number;
  w0newGame: string;
  w0newGamePrice: string;
  w1newGamePrice: number;
  w0title: string;
  w0plusMoney: string;
  w1plusMoney: number;
  w1plusMoneyU: number;
  w0AVmoney: string;
  w1AVmoney: number;
  maxDate: Date;
  w0startDate: any
  w0updatetime: any
  w0symbolID: any
  w0endDate: any
  dateFilter: any
  dateFilter1: any = ''
  dateFilter2: any = ''
  dateFilter3: any = ''
  w1startDate: number
  w1endDate: number
  countCustomer: number
  countDeposit: number
  countCharge: number
  changerlist=[{"changer":"开","value":"Y"},{"changer":"关","value":"N"}]
  changerlistU=[{"changerU":"开","value":"Y"},{"changerU":"关","value":"N"},{"changerU":"超管模式","value":"A"}]
  changerlistX=[{"changerX":"只追涨","value":"Y"},{"changerX":"只抄底","value":"N"},{"changerX":"追涨抄底","value":"B"}]
  countAvailableMoney: number
  countAvailableMoneyTotal: number
  countCustomerTotal: number
  nameFlag: boolean = true
  mobileFlag: boolean = true
  cardNumberFlag: boolean = true
  plusMoneyFlag: boolean = true
  itemsFlag: boolean = true
  priceFlag: boolean = true
  newGameFlag: boolean = true
  newGamePriceFlag: boolean = true
  AVmoneyFlag: boolean = true
  DateStartFlag: boolean = true
  DateEndFlag: boolean = true
  url_Production: string = "http://47.242.91.179:82/"     
  url_local: string = "http://localhost:82/"      //"http://localhost:82/"
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['symbolID', 'functionID', 'score', 'updatetime'];
  transactionColumns = ['name', 'symbolID', 'amount', 'volumn', 'openprice', 'stopprice', 'updatedate', 'updatetime'];
  rankColumns = ['symbolID', 'score'];
  hasUnitNumber = false;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort
  dataSource = new MatTableDataSource<PeriodicElement>(this.item);
  transactionSource = new MatTableDataSource<TransactionElement>(this.item);
  rankSource = new MatTableDataSource<RankElement>(this.item);
  blankSource = new MatTableDataSource<PeriodicElement>();
  blankTransaction = new MatTableDataSource<TransactionElement>();
  blankRank = new MatTableDataSource<RankElement>();
  todayDate: Date


  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.paginator.page
      .pipe(
        tap(() => this.enqNotice())
      )
      .subscribe
    this.transactionSource.paginator = this.paginator;
    this.transactionSource.sort = this.sort;
    this.paginator.page
      .pipe(
        tap(() => this.EnqTransaction())
      )
      .subscribe
    this.rankSource.paginator = this.paginator;
    this.rankSource.sort = this.sort;
    this.paginator.page
      .pipe(
        tap(() => this.Enqrank())
      )
      .subscribe
  }
  

  ngOnInit() {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.addressForm = new FormGroup({
      symbolID: new FormControl(''),
      updatetime: new FormControl(''),
      name: new FormControl('',[Validators.maxLength(20),Validators.minLength(3)]),
      changer: new FormControl(''),
      changerU: new FormControl(''),
      changerX: new FormControl(''),
      amount: new FormControl('',[Validators.max(100000),Validators.min(10)]),
      contractamount: new FormControl('',[Validators.max(100000),Validators.min(3)]),
      contractamountX: new FormControl(''),
    });
    this.cdr.detectChanges();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.transactionSource.paginator = this.paginator;
    this.transactionSource.sort = this.sort;
    this.rankSource.paginator = this.paginator;
    this.rankSource.sort = this.sort;
    //Retrieve Item List

    this.retrieveItem()
    this.maxDate = new Date;
    console.log(this._parent.DB_Name)
    this.retrieveRobot()

  }

  constructor(private fb: FormBuilder, private http: HttpClient, public _snackBar: MatSnackBar, public _parent: MointorControlComponent, private _adapter: DateAdapter<any>, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer, private cdr: ChangeDetectorRef) {
    iconRegistry.addSvgIcon(
      'Money',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/Money-icon.svg'));
    iconRegistry.addSvgIcon(
      'phone',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/phone.svg'));
    iconRegistry.addSvgIcon(
      'name',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/name.svg'));
    iconRegistry.addSvgIcon(
      'cardnumber',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/cardnumber.svg'));
    iconRegistry.addSvgIcon(
      'date',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/date.svg'));
    iconRegistry.addSvgIcon(
      'item',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/item.svg'));
    iconRegistry.addSvgIcon(
      'charge',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/charge.svg'));
    iconRegistry.addSvgIcon(
      'availableMoney',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/availableMoney.svg'));
  }
  @Output() private outer = new EventEmitter();
  async onSubmit(form) {
    //  alert('Thanks!');
    this.reSet()
    console.log(this._parent.DB_Name)
    var object = form.value;
    this.w0symbolID = object.symbolID
    this.w0name=object.name
    this.w0changer=object.changer
    this.w0changerU=object.changerU
    this.w0changerX=object.changerX
    this.w0amount=object.amount
    this.w0contractamount=object.contractamount
    this.w0contractamountX=object.contractamountX
    this.w1plusMoney = parseInt(object.amount)
    this.w1plusMoneyU = parseInt(object.contractamount)
    this.w0updatetime = moment(object.updatetime).format('YYYYMMDD')
    this.paginator.pageIndex = 0
    //this.formatSQL()
    //this.paginator.pageIndex=0;

    if (this._parent.flag == '1') {//查询币种监控
      if (this.w0symbolID == '' || this.w0updatetime=='') {
        this.w0title = '币种和日期必须输入'
        this.openSnackBar(this.w0title, w0error)
      }
        else {

          this.enqNotice();
        }
    }
    if (this._parent.flag == '4') {//查询大盘指数

      this.Enqwaterfall();
}
    if (this._parent.flag == '5') {//查询自选币排名

      this.Enqrank();
}
    if (this._parent.flag == '6') {//查询机器人历史卖单

          this.EnqTransaction();

    }
    if (this._parent.flag == '8') {//修改机器人参数
      if (this.w0name == '' || this.w0amount==''|| this.w0contractamount==''||this.w0changer==""||this.w0changerU==""||this.w0changerX=="") {
        this.w0title = '姓名+金额+开关不能为空'
        this.openSnackBar(this.w0title, w0error)
      }
      else if
      (isNaN(this.w1plusMoney)) {
        this.w0title = '金额不要为空'
        this.openSnackBar(this.w0title, w0warn)
      }      else if
      (this.w1plusMoney<3||this.w1plusMoney>10000) {
        this.w0title = '金额最小值是3,最大值是10000'
        this.openSnackBar(this.w0title, w0warn)
      } 
      else if
      (isNaN(this.w1plusMoneyU)) {
        this.w0title = '金额不要为空'
        this.openSnackBar(this.w0title, w0warn)
      }      else if
      (this.w1plusMoneyU<3||this.w1plusMoney>10000) {
        this.w0title = '金额最小值是10,最大值是10000'
        this.openSnackBar(this.w0title, w0warn)
      } 
        else {
          this.UpdateRobot();
        }
    }
  }
  //消息提醒
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  openwaterBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 200000,
    });
  }

  //指定客户信息查询
  enqNotice() {
    let params = {
      "symbolID": this.w0symbolID,
      "updatetime": this.w0updatetime,
    }
    this.reSet()
    var url = this.url_Production + "Btc/Enqnoticehistory/"
    this.http.post(url, params, { headers: headers }).subscribe(res => {
      this.anyList = res
      console.log(this.anyList)
      this.result = this.anyList.message;
      this.submitFlag = true;
      this.custTotal = this.result.length;
      this.custItem = [];
      for (let i = 0; i < this.result.length; i++) {
        this.custItem.push(this.result[i])
      }
      this.dataSource = new MatTableDataSource<PeriodicElement>(this.custItem)
    });
  }
  
  EnqTransaction() {
    let params = {
      "updatetime": this.w0updatetime,
      "accountID": this._parent.accountID
    }
    this.reSet()
    var url = this.url_Production + "Btc/Enqtransaction/"
    this.http.post(url, params, { headers: headers }).subscribe(res => {
      this.anyList = res
      console.log(this.anyList)
      this.result = this.anyList.message;
      this.submitFlag = true;
      this.custTotal = this.result.length;
      this.custItem = [];
      for (let i = 0; i < this.result.length; i++) {
        this.custItem.push(this.result[i])
      }
      this.transactionSource = new MatTableDataSource<TransactionElement>(this.custItem)
    });
  }

  Enqrank() {
    this.reSet()
    var url = this.url_Production + "Btc/Enqrank/"
    this.http.get(url, { headers: headers }).subscribe(res => {
      this.anyList = res
      console.log(this.anyList)
      this.result = this.anyList.message;
      this.submitFlag = true;
      this.custTotal = this.result.length;
      this.custItem = [];
      for (let i = 0; i < this.result.length; i++) {
        this.custItem.push(this.result[i])
      }
      this.rankSource = new MatTableDataSource<RankElement>(this.custItem)
    });
  }

  Enqwaterfall() {
    this.reSet()
    var url = this.url_Production + "Btc/Enqwaterfall/"
    this.http.get(url, { headers: headers }).subscribe(res => {
      this.anyList = res
      console.log(this.anyList)
      this.result = this.anyList.message;
      this.submitFlag = true;
      this.custTotal = this.result.length;
      this.custItem = [];
      for (let i = 0; i < this.result.length; i++) {
        this.custItem.push(this.result[i])
      }
      this.waterfall1 = this.custItem[0].score+"@"+ this.custItem[0].updatetime
      this.waterfall2 = this.custItem[1].score+"@"+ this.custItem[1].updatetime
      this.waterfall3 = this.custItem[2].score+"@"+ this.custItem[2].updatetime
      this.waterfall4 = this.custItem[3].score+"@"+ this.custItem[3].updatetime
      this.waterfall5 = this.custItem[4].score+"@"+ this.custItem[4].updatetime
      this.waterfall6 = this.custItem[5].score+"@"+ this.custItem[5].updatetime
      this.waterfall7 = this.custItem[6].score+"@"+ this.custItem[6].updatetime
      this.waterfall8 = this.custItem[7].score+"@"+ this.custItem[7].updatetime
      this.waterfall9 = this.custItem[8].score+"@"+ this.custItem[8].updatetime
      this.waterfall10 = this.custItem[9].score+"@"+ this.custItem[9].updatetime
    });
  }
  //初始化
  reSet() {
    this.dataSource = this.blankSource;
    this.transactionSource = this.blankTransaction;
    this.rankSource=this.blankRank;
    this.custItem = [];
    this.item = [];
    this.total = 0;
    this.anyList = '';
    this.dateFilter = '';
    this.dateFilter1 = '';
    this.dateFilter2 = '';
    this.dateFilter3 = '';
    this.paginator.pageIndex = 0;
    this.w0title = " "
    this.waterfall1=" "
    this.waterfall2=" "
    this.waterfall3=" "
    this.waterfall4=" "
    this.waterfall5=" "
    this.waterfall6=" "
    this.waterfall7=" "
    this.waterfall8=" "
    this.waterfall9=" "
    this.waterfall10=" "

  }


  //获取消费项目信息

  retrieveItem(){
    var url = this.url_Production + "Btc/Enqselfchoice/"
    this.http.get(url, { headers: headers }).subscribe(res => {
      this.anyList = res
      console.log(this.anyList)
      this.itemList = this.anyList.message;
    });
  }
  retrieveRobot() {
    let params = {
      "profile": this._parent.profile,
      "password": this._parent.password,
    }
    this.reSet()
    var url = this.url_Production + "Btc/RetrieveRobot/"
    this.http.post(url, params, { headers: headers }).subscribe(res => {
      this.anyList = res
      console.log(this.anyList)
      this.result = this.anyList.message;
      this.custItem.push(this.result)
      this.w0name=this.custItem[0][0].name
      this.w0changer=this.custItem[0][0].autoflag
      this.w0changerU=this.custItem[0][0].contractautoflag
      this.w0changerX=this.custItem[0][0].superflag
      this.w0amount=this.custItem[0][0].amount
      this.w0contractamount=this.custItem[0][0].contractamount
      this.w0contractamountX=this.custItem[0][1].contractamount
      this.addressForm.patchValue({name: this.w0name});
      this.addressForm.patchValue({changer: this.w0changer});
      this.addressForm.patchValue({changerU: this.w0changerU});
      this.addressForm.patchValue({changerX: this.w0changerX});
      this.addressForm.patchValue({amount: this.w0amount});
      this.addressForm.patchValue({contractamount: this.w0contractamount});
      this.addressForm.patchValue({contractamountX: this.w0contractamountX});
    });
  }
  UpdateRobot() {
    let params = {
      "profile": this._parent.profile,
      "password": this._parent.password,
      "name":this.w0name,
      "changer":this.w0changer,
      "changerU":this.w0changerU,
      "changerX":this.w0changerX,
      "amount":this.w0amount,
      "contractamount":this.w0contractamount,
      "contractamountX":this.w0contractamountX
    }
    var url = this.url_Production + "Btc/UpdateRobot/"
    this.http.post(url, params, { headers: headers }).subscribe(res => {
      this.anyList = res
      console.log(this.anyList)
      this.result = this.anyList.message;
      this.w0title = '更新成功'
      this.openSnackBar(this.w0title, w0reminder)
    });
  }

  AKcontract() {
    let params = {
      "profile": this._parent.profile,
      "password": this._parent.password,
    }
    var url = this.url_Production + "Btc/akcontract/"
    this.http.post(url, params, { headers: headers }).subscribe(res => {
      this.anyList = res
      console.log(this.anyList)
      this.result = this.anyList.message;
      this.w0title = '提交申请成功,两分钟内自动清仓关闭机器人'
      this.openSnackBar(this.w0title, w0reminder)
    });
  }

}
