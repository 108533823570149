<form [formGroup]="addressForm" (ngSubmit)="onSubmit(addressForm)">
  <mat-card class="shipping-card">
    <mat-card-header>
      <mat-card-title style="color: red;" *ngIf="_parent.flag=='1'">查询币异动</mat-card-title>
      <mat-card-title style="color: red;" *ngIf="_parent.flag=='4'">大盘评分查询</mat-card-title>
      <mat-card-title style="color: red;" *ngIf="_parent.flag=='5'">自选币评分查询</mat-card-title>
      <mat-card-title style="color: red;" *ngIf="_parent.flag=='6'">机器人历史卖单查询</mat-card-title>
      <mat-card-title style="color: red;" *ngIf="_parent.flag=='8'">机器人参数修改</mat-card-title>
      <mat-card-title style="color: red;" *ngIf="_parent.flag=='9'">账号密码修改</mat-card-title>
    </mat-card-header>
    <mat-card-content>

      <mat-form-field *ngIf="_parent.flag=='1'||_parent.flag=='6'">
        <input matInput [matDatepicker]="picker" placeholder="日期" formControlName="updatetime" [max]='maxDate'>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <div class="row" *ngIf="_parent.flag=='1'">

        <div class="row">
          <mat-form-field>
            <mat-select formControlName="symbolID" matInput placeholder="币种">
              <mat-option>None</mat-option>
              <ng-container *ngFor="let i of itemList">
                <mat-option value={{i.symbolID}}>{{i.symbolID}}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>

        </div>
      </div>
      <div class="row" *ngIf="_parent.flag=='8'">
        <div class="col">
          <mat-form-field class="full-width">
            <input matInput placeholder="昵称" formControlName="name">
          </mat-form-field>
        </div>
      </div>

      <mat-card class="example-card" *ngIf="_parent.flag=='4'">
        <mat-card-header>
        <div mat-card-avatar class="example-header-image"></div>
        <mat-card-title>大盘评分指数</mat-card-title>
        <mat-card-subtitle>正数涨，负数跌</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
        <p style="color: blue;">
          {{waterfall1}}
        </p>
        <p style="color: blue;">
          {{waterfall2}}
        </p>
        <p style="color: blue;">
          {{waterfall3}}
        </p>
        <p style="color: blue;">
          {{waterfall4}}
        </p>
        <p style="color: blue;">
          {{waterfall5}}
        </p>
        <p style="color: blue;">
          {{waterfall6}}
        </p>
        <p style="color: blue;">
          {{waterfall7}}
        </p>
        <p style="color: blue;">
          {{waterfall8}}
        </p>
        <p style="color: blue;">
          {{waterfall9}}
        </p>
        <p style="color: blue;">
          {{waterfall10}}
        </p>
        </mat-card-content>
        <mat-card-actions>
        </mat-card-actions>
        </mat-card>

      <div class="row" *ngIf="_parent.flag=='8'">
        <mat-form-field>
          <mat-select formControlName="changer" matInput placeholder="现货自动交易开关">
            <mat-option>None</mat-option>
            <ng-container *ngFor="let i of changerlist">
              <mat-option value={{i.value}}>{{i.changer}}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-select formControlName="changerU" matInput placeholder="合约自动交易开关">
            <mat-option>None</mat-option>
            <ng-container *ngFor="let i of changerlistU">
              <mat-option value={{i.value}}>{{i.changerU}}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>

      </div>
      <div class="row" *ngIf="_parent.flag=='8'">
        <div class="col">
          <mat-form-field class="full-width">
            <input matInput placeholder="现货单笔成交额（单位是USDT）" formControlName="amount">
          </mat-form-field>
        </div>

        <div class="col">
          <mat-form-field class="full-width">
            <input matInput placeholder="合约单笔保证金（单位是USDT,最好总金额能开50单以上）" formControlName="contractamount">
          </mat-form-field>
        </div>
      </div>
      <div class="row" *ngIf="_parent.flag=='8'">
      <mat-form-field>
        <mat-select formControlName="changerX" matInput placeholder="当前合约模式（系统参数）" disabled>
          <mat-option>None</mat-option>
          <ng-container *ngFor="let i of changerlistX">
            <mat-option value={{i.value}}>{{i.changerX}}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
      <div class="col">
        <span style="color: brown;"> 当前大饼现货自动抄底触发价位： {{w0contractamountX}}    </span>
        </div>
    </div>


    </mat-card-content>
    <mat-card-actions *ngIf="_parent.flag=='1'|| _parent.flag=='8'|| _parent.flag=='6'|| _parent.flag=='5'|| _parent.flag=='4'">
      <button mat-raised-button color="primary" type="submit">提交 </button>
    </mat-card-actions>
    <mat-card-actions *ngIf="_parent.flag=='8'">
      <button mat-raised-button color="warn" (click)="AKcontract()" type ="button">一键清空合约 </button>
    </mat-card-actions>
  </mat-card>

  <div class="mat-elevation-z8">
    <!--历史异动-->
    <table mat-table [dataSource]="dataSource" class="full-width-table" matSort aria-label="Elements">
      <!-- Id Column -->
      <ng-container matColumnDef="symbolID">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="expr-table-header">币种<mat-icon
            svgIcon="cardnumber"></mat-icon>
        </th>
        <td mat-cell *matCellDef="let row" class="normalCust">{{row.symbolID}}</td>
      </ng-container>


      <!-- Name Column -->
      <ng-container matColumnDef="functionID">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="expr-table-header">异动名称<mat-icon svgIcon="item">
          </mat-icon>
        </th>
        <td mat-cell *matCellDef="let row" class="normalCust">{{row.functionID}}</td>
      </ng-container>

      <ng-container matColumnDef="score">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="expr-table-header">分数<mat-icon svgIcon="charge">
          </mat-icon>
        </th>
        <td mat-cell *matCellDef="let row" class="normalCust">{{row.score}}</td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="updatetime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="expr-table-header">日期<mat-icon svgIcon="date">
          </mat-icon>
        </th>
        <td mat-cell *matCellDef="let row" class="normalCust">{{row.updatetime}}</td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <!--<div class ="spinner-container" *ngif ="loading$ | async" >
          <mat-spinner></mat-spinner>
        </div>  -->
    <mat-paginator [length]="custTotal" [pageIndex]="0" [pageSize]="20" [pageSizeOptions]="[10, 20, 50]"
      showFirstLastButtons> </mat-paginator>
  </div>

  <div class="mat-elevation-z8">
    <!--历史机器人交易-->
    <table mat-table [dataSource]="transactionSource" class="full-width-table" matSort aria-label="Elements">
      <!-- Id Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="expr-table-header">昵称<mat-icon
            svgIcon="cardnumber"></mat-icon>
        </th>
        <td mat-cell *matCellDef="let row" class="normalCust">{{row.name}}</td>
      </ng-container>


      <!-- Name Column -->
      <ng-container matColumnDef="symbolID">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="expr-table-header">币种<mat-icon svgIcon="item">
          </mat-icon>
        </th>
        <td mat-cell *matCellDef="let row" class="normalCust">{{row.symbolID}}</td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="expr-table-header">成交额（U）<mat-icon
            svgIcon="charge"></mat-icon>
        </th>
        <td mat-cell *matCellDef="let row" class="normalCust">{{row.amount}}</td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="volumn">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="expr-table-header">成交量<mat-icon svgIcon="date">
          </mat-icon>
        </th>
        <td mat-cell *matCellDef="let row" class="normalCust">{{row.volumn}}</td>
      </ng-container>
      <!-- Name Column -->
      <ng-container matColumnDef="openprice">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="expr-table-header">买入市价<mat-icon svgIcon="item">
          </mat-icon>
        </th>
        <td mat-cell *matCellDef="let row" class="normalCust">{{row.openprice}}</td>
      </ng-container>

      <ng-container matColumnDef="stopprice">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="expr-table-header">挂出价格<mat-icon svgIcon="charge">
          </mat-icon>
        </th>
        <td mat-cell *matCellDef="let row" class="normalCust">{{row.stopprice}}</td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="updatedate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="expr-table-header">日期<mat-icon svgIcon="date">
          </mat-icon>
        </th>
        <td mat-cell *matCellDef="let row" class="normalCust">{{row.updatedate}}</td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="updatetime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="expr-table-header">时间<mat-icon svgIcon="date">
          </mat-icon>
        </th>
        <td mat-cell *matCellDef="let row" class="normalCust">{{row.updatetime}}</td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="transactionColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: transactionColumns;"></tr>
    </table>
    <!--<div class ="spinner-container" *ngif ="loading$ | async" >
          <mat-spinner></mat-spinner>
        </div>  -->
    <mat-paginator [length]="custTotal" [pageIndex]="0" [pageSize]="20" [pageSizeOptions]="[10, 20, 50]"
      showFirstLastButtons> </mat-paginator>
  </div>

  <div class="mat-elevation-z8">
    <!--自选币排名-->
    <table mat-table [dataSource]="rankSource" class="full-width-table" matSort aria-label="Elements">
      <!-- Id Column -->
      <ng-container matColumnDef="symbolID">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="expr-table-header">币种<mat-icon
            svgIcon="cardnumber"></mat-icon>
        </th>
        <td mat-cell *matCellDef="let row" class="normalCust">{{row.symbolID}}</td>
      </ng-container>


      <!-- Name Column -->
      <ng-container matColumnDef="score">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="expr-table-header">分数<mat-icon svgIcon="item">
          </mat-icon>
        </th>
        <td mat-cell *matCellDef="let row" class="normalCust">{{row.score}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="rankColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: rankColumns;"></tr>
    </table>
    <!--<div class ="spinner-container" *ngif ="loading$ | async" >
          <mat-spinner></mat-spinner>
        </div>  -->
    <mat-paginator [length]="custTotal" [pageIndex]="0" [pageSize]="20" [pageSizeOptions]="[10, 20, 50]"
      showFirstLastButtons> </mat-paginator>
  </div>

</form>