import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dnfadmin',
  templateUrl: './dnfadmin.component.html',
  styleUrls: ['./dnfadmin.component.css']
})
export class DNFAdminComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
