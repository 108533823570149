import { Component, OnInit,Predicate,ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment'
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

export interface PeriodicCustElement {
  symbolID: string;
}
const w0warn = '警告'
const w0reminder = '通知'
const w0error = '错误'
const headers = {
  "Access-Control-Allow-Origin": '*',
}

@Component({
  selector: 'app-selfchoice',
  templateUrl: './selfchoice.component.html',
  styleUrls: ['./selfchoice.component.css']
})
export class SelfchoiceComponent implements OnInit {
  SMSFLAG:boolean =false
  itemList: []
  total: number = 0;
  custTotal: number = 0;
  item = [];
  custItem = [];
  flag:string;
  w0title: string;
  status:boolean;
  symbolID:string;
  weather:string;
  password:string;
  detail:string;
  w0date: string;
  todayDate: any;
  Days:any
  public result: [];
  public result1: [];
  public result2: [];
  public anyList: any;
  public submitFlag: any;
  public DB_Profile: any;
  public DB_Password: any;
  public DB_Name: any;
  BossForm:FormGroup;
  url_Production: string = "http://47.114.171.251:82/"       //"http://47.114.171.251:82/"
  url_local: string = "http://localhost:82/"      //"http://localhost:82/"
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort
  dataSource = new MatTableDataSource<PeriodicCustElement>(this.item);
  blankDataSource = new MatTableDataSource<PeriodicCustElement>();
  displayedColumns = ['symbolID'];
  constructor(public _snackBar: MatSnackBar,private http: HttpClient) { }

  ngOnInit(): void {
    this.BossForm = new FormGroup({
      symbolID: new FormControl(''),
      flag:new FormControl('1')

    });
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.flag='1'
    this.todayDate =moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
  }

  submit(form){
    let object =form.value
    this.symbolID = object.symbolID
    this.flag= object.flag;
    this.dataSource.paginator.pageIndex=0

  if(this.flag=='1'){
    this.EnquiryDiary()
    this.SMSFLAG =true
  } else

  if(this.flag=='2'){
    this.CreateDiary()
    this.SMSFLAG =true
}
else
if(this.flag=='3'){
  this.CreateDiary()
  this.SMSFLAG =true
}
else

if(this.flag=='999'){
this.BossForm.reset({
});
this.status=false;
this.flag='0';
}
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
    });
  }

  ColorClass1(flag){
    let x =flag;
    let className = '';
    if(x==1){
      className = 'RedColor';
    }
    return className
  }
  ColorClass2(flag){
    let x =flag;
    let className = '';
    if(x==2){
      className = 'RedColor';
    }
    return className
  }
 
  CreateDiary(){
    this.todayDate =moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
    let params = {
      "symbolID": this.symbolID

    }
    var url = this.url_Production + "Others/SelfChoice/CreateChoice/"
    this.http.post(url, params, { headers: headers }).subscribe(res => {
      this.anyList = res
      console.log(this.anyList)
      this.w0title = this.anyList.message
      this.openSnackBar(this.w0title, w0reminder)
      this.status=true;
    });
  }

  EnquiryDiary(){
    var url = this.url_Production + "Others/SelfChoice/EnquiryChoice/"
    this.http.get(url,  { headers: headers }).subscribe(res => {
      this.anyList = res
      console.log(this.anyList)
      this.result = this.anyList.data;
      this.total = this.result.length;
      this.submitFlag = true;
      this.item = [];
      for (let i = 0; i < this.result.length; i++) {
        this.item.push(this.result[i])
      }
      this.dataSource = new MatTableDataSource<PeriodicCustElement>(this.item)
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

}

