import { Component, OnInit, Output, EventEmitter, ViewChild, ChangeDetectorRef,AfterViewInit} from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableModule } from "@angular/material/table";
import * as moment from 'moment'
import { MatSnackBar } from '@angular/material/snack-bar';
import { ifStmt } from '@angular/compiler/src/output/output_ast';
import { ITS_JUST_ANGULAR } from '@angular/core/src/r3_symbols';
import { isNull } from 'util';
import { Observable } from "rxjs";
import { delay } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { BehaviorSubject } from "rxjs";
import { tap } from "rxjs/operators";
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MointorControlComponent } from 'src/app/BTC/mointor-control/mointor-control.component'
export interface PeriodicElement {
  symbol: string;
  direction: string;
  entryPrice: string;
  markPrice: string;
  overPrice: string;
  scale:string;
  remaincount:string;
  profit:string;
  ticket_value:string;
  ticket_count:string;


}
const w0warn = '警告'
const w0reminder = '通知'
const w0error = '错误'
const headers = {
  "Access-Control-Allow-Origin": '*',
}


@Component({
  selector: 'app-super-admin',
  templateUrl: './super-admin.component.html',
  styleUrls: ['./super-admin.component.css'],
  template: `{{_parent.flag}},{{_parent.profile}},{{_parent.password}},{{_parent.accountID}}`
})
export class SuperAdminComponent implements AfterViewInit {
  itemList: []
  nameList: []
  flag:number =0;
  submitflag:number=0;
  w0title: string;
  status:boolean;
  symbolID:string;
  direction:string;
  w0message:string
  price:string;
  profitprice:string;
  stopprice:string;
  Access_Key:string;
  Secret_Key:string;
  Passphrase:string;
  Access_Key_:string;
  Secret_Key_:string;
  Passphrase_:string;
  follow_percent:string;
  name:string;
  ticket_count;
  ticket_value;
  item = [];
  custTotal: number = 0;
  access_key:string;
  secret_key:string;
  w0date: string;
  todayDate: Date;
  forceflag:string;
  positionSide:string;
  percent:string;
  w0forceflag:string
  w0percent:string
  w0positionSide:string
  w0submitflag:string
  public result: [];
  public anyList: any;
  public submitFlag: any;
  public DB_Profile: any;
  public DB_Password: any;
  public DB_Name: any;
  BossForm:FormGroup;
  url_Production: string = "http://47.242.91.179:82/"     
  url_local: string = "http://localhost:82/"      //"http://localhost:82/"
  directionlist=[{"direction":"做多","value":"BUY"},{"direction":"做空","value":"SELL"}]
  forcelist=[{"force":"强制平仓不检查收益率","value":"Y"},{"force":"平仓前检查收益率","value":"N"}]
  positionlist=[{"position":"多","value":"long"},{"position":"空","value":"short"}]
  percentlist=[{"percent":"1/4","value":"0.25"},{"percent":"1/2","value":"0.5"},{"percent":"全部","value":"1"}]
  w0symbolID: any
  w0name: any
  w0direction: any
  w0price: any
  w0profitprice: any
  w0ticket_count:any
  w0ticket_value:any
  w0stopprice: any
  w0Access_Key:any
  w0Secret_Key:any
  w0Passphrase:any
  w0Access_Key_:any
  w0Secret_Key_:any
  w0Passphrase_:any
  w0follow_percent:any
  displayedColumns = ['symbol', 'direction', 'entryPrice', 'markPrice','overPrice','scale','remaincount','profit'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource = new MatTableDataSource<PeriodicElement>(this.item);
  blankSource = new MatTableDataSource<PeriodicElement>();

  

  constructor(public _snackBar: MatSnackBar,private http: HttpClient,iconRegistry: MatIconRegistry, sanitizer: DomSanitizer, private cdr: ChangeDetectorRef,public _parent: MointorControlComponent) {
    iconRegistry.addSvgIcon(
      'coin',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/coin.svg'));
    iconRegistry.addSvgIcon(
      'over',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/over.svg'));
    iconRegistry.addSvgIcon(
      'direction',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/direction.svg'));
    iconRegistry.addSvgIcon(
      'open',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/open.svg'));
    iconRegistry.addSvgIcon(
      'marketing',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/marketing.svg'));
    iconRegistry.addSvgIcon(
      'percent',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/percent.svg'));
    iconRegistry.addSvgIcon(
      'count',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/count.svg'));
    iconRegistry.addSvgIcon(
      'profit',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/profit.svg'));
    iconRegistry.addSvgIcon(
      'availableMoney',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/availableMoney.svg'));
   }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.paginator.page
      // .pipe(
      //   tap(() => this.enqcontract())
      // )
      // .subscribe
  }
  



  ngOnInit(): void {
    this.dataSource = this.blankSource;
    this.BossForm = new FormGroup({
      name:new FormControl(''),
      symbolID: new FormControl(''),
      direction: new FormControl(''),
      price: new FormControl(''),
      profitprice: new FormControl(''),
      stopprice: new FormControl(''),
      forceflag: new FormControl('N'),
      positionSide: new FormControl('long'),
      percent: new FormControl('1'),
      submitflag: new FormControl(0),
      Access_Key:new FormControl(''),
      Secret_Key:new FormControl(''),
      Passphrase:new FormControl('NONE'),
      Access_Key_:new FormControl(''),
      Secret_Key_:new FormControl(''),
      Passphrase_:new FormControl('NONE'),
      follow_percent:new FormControl(''),
      ticket_count:new FormControl('5'),
      ticket_value:new FormControl('350')

      
    });
    this.flag=0
    this.submitflag=0
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    console.log(this._parent.flag)
    this.retrieveItem()
    this.retrieveName()
  }
  @Output() private outer = new EventEmitter();
  submit(form){
    console.log(this._parent.flag)
    let object =form.value
    this.w0name = object.name
    this.w0symbolID = object.symbolID
    this.w0direction = object.direction
    this.w0price = object.price
    this.w0profitprice = object.profitprice
    this.w0stopprice = object.stopprice
    this.w0forceflag = object.forceflag
    this.w0positionSide = object.positionSide
    this.w0percent = object.percent
    this.w0submitflag=object.submitflag
    this.w0Access_Key=object.Access_Key
    this.w0Secret_Key=object.Secret_Key
    this.w0Passphrase=object.Passphrase
    this.w0Access_Key_=object.Access_Key_
    this.w0Secret_Key_=object.Secret_Key_
    this.w0Passphrase_=object.Passphrase_
    this.w0follow_percent=object.follow_percent
    this.w0ticket_count=object.ticket_count
    this.w0ticket_value=object.ticket_value
    this.name =object.name;
    this.symbolID =object.symbolID;
    this.direction = object.direction;
    this.price= object.price;
    this.profitprice= object.profitprice;
    this.ticket_count=object.ticket_count;
    this.ticket_value=object.ticket_value;
    this.stopprice= object.stopprice;
    this.Access_Key=object.Access_Key
    this.Secret_Key=object.Secret_Key
    this.Passphrase=object.Passphrase
    this.Access_Key_=object.Access_Key_
    this.Secret_Key_=object.Secret_key_
    this.Passphrase_=object.Passphrase_
    this.follow_percent=object.follow_percent
    this.forceflag= object.forceflag;
    this.positionSide= object.positionSide;
    this.percent= object.percent;
    this.submitflag = object.submitflag
    this.todayDate = new Date();
    this.w0date = moment(this.todayDate).format('YYYYMMDD')
    this.paginator.pageIndex = 0
    // this.dataSource = this.blankSource;


//   if(this.flag==2){
//     this.w0title = '跳转机器人开单'
//     this.openSnackBar(this.w0title, w0reminder)
//     this.flag=0
// }
if (this.submitFlag==88){
  if (this.w0name!=""){
    this.dataSource = this.blankSource;
    this.enqcontract()

  } else{
   this.w0title = '都是必填项'
  this.openSnackBar(this.w0title, w0reminder)
  }

  }
  if (this.submitFlag==2){
  if (this.w0symbolID!="" && this.w0direction!=""&& this.w0price!=""){
    this.opencontract()

  } else{
   this.w0title = '除了止盈止损都是必填项'
  this.openSnackBar(this.w0title, w0reminder)
  }

  }

  if (this.submitFlag==3){
    if (this.w0symbolID!="" && this.w0forceflag!="" && this.w0positionSide!="" && this.w0percent!=""){
      this.clearcontract()
  
    } else{
     this.w0title = '都是必填项'
    this.openSnackBar(this.w0title, w0reminder)
    }
  
    }
  if (this.submitFlag==4){
    if (this.w0symbolID!=""){
      this.cancelcontract()
  
    } else{
      this.w0title = '都是必填项'
    this.openSnackBar(this.w0title, w0reminder)
    }
  
    }
    
  if (this.submitFlag==5){
      if (this.w0Access_Key!="" && this.w0Secret_Key!="" && this.w0Passphrase!="" && this.w0Access_Key_!="" && this.w0Secret_Key_!="" && this.w0Passphrase_!="" && this.w0follow_percent!=""){
        this.follow()
    
      } else{
        this.w0title = '都是必填项'
      this.openSnackBar(this.w0title, w0reminder)
      }
    
      }
      if (this.submitFlag==6){
        if (this.w0name!=""){
          this.dataSource = this.blankSource;
          this.housekeepcontract()
      
        } else{
         this.w0title = '都是必填项'
        this.openSnackBar(this.w0title, w0reminder)
        }
      
        }
  
        if (this.submitFlag==7){
          if (this.w0ticket_count!="" && this.w0ticket_value!=""){
            this.dataSource = this.blankSource;
            this.createticket()
        
          } else{
           this.w0title = '都是必填项'
          this.openSnackBar(this.w0title, w0reminder)
          }
        
          }
  
  this.submitFlag=0
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
    });
  }

  opencontract() {
    this.w0title = '提交开单...'
    this.w0message="waiting"
    this.openSnackBar(this.w0title, this.w0message)
    let params = {
      "symbolID": this.w0symbolID,
      "direction": this.w0direction,
      "price": this.w0price,
      "profitprice": this.w0profitprice,
      "stopprice": this.w0stopprice
    }
    var url = this.url_Production + "Btc/OpenContract/"
    this.http.post(url, params, { headers: headers }).subscribe(res => {
      this.anyList = res
      console.log(this.anyList)
      this.result = this.anyList.message;
      if(this.result==["下单成功"]){
      this.w0title = '机器人开单情况'
      this.w0message="成功"
      this.openSnackBar(this.w0title, this.w0message)
      }
      if(this.result==["下单失败，请联系管理员"]){
        this.w0title = '机器人开单情况'
        this.w0message="下单失败，请联系管理员"
        this.openSnackBar(this.w0title, this.w0message)
        }
        if(this.result==["目前没有用户开启管理员模式"]){
          this.w0title = '机器人开单情况'
          this.w0message="目前没有用户开启管理员模式"
          this.openSnackBar(this.w0title, this.w0message)
          }
    });
  }

  createticket() {
    this.w0title = '提交创券...'
    this.w0message="waiting"
    this.openSnackBar(this.w0title, this.w0message)
    let params = {
      "ticket_value": this.w0ticket_value,
      "ticket_count": this.w0ticket_count
    }
    var url = this.url_Production + "Btc/CreateTicket/"
    this.http.post(url, params, { headers: headers }).subscribe(res => {
      this.anyList = res
      console.log(this.anyList)
      this.result = this.anyList.message;
      this.openSnackBar(this.w0title, String(this.result))
    });
  }

  follow() {
    this.w0title = '提交跟单...'
    this.w0message="waiting"
    this.openSnackBar(this.w0title, this.w0message)
    let params = {
      "Access_Key": this.w0Access_Key,
      "Secret_Key": this.w0Secret_Key,
      "Passphrase": this.w0Passphrase,
      "Access_Key_": this.w0Access_Key_,
      "Secret_Key_": this.w0Secret_Key_,
      "Passphrase_": this.w0Passphrase_,
      "follow_percent": this.w0follow_percent
    }
    var url = this.url_Production + "Btc/webFollow/"
    this.http.post(url, params, { headers: headers }).subscribe(res => {
      this.anyList = res
      console.log(this.anyList)
      this.result = this.anyList.message;

      this.w0title = '跟单提交状态'
      this.openSnackBar(this.w0title, this.result.toString())

    });
  }


  enqcontract() {
    this.w0title = '提交查询...'
    this.w0message="waiting"
    this.openSnackBar(this.w0title, this.w0message)
    let params = {
      "name": this.w0name
    }
    var url = this.url_Production + "Btc/EnqContract/"
    this.http.post(url, params, { headers: headers }).subscribe(res => {
      this.anyList = res
      console.log(this.anyList)
      this.result = this.anyList.message;
      this.submitFlag = true;
      this.custTotal = this.result.length;
      this.item = [];
      for (let i = 0; i < this.result.length; i++) {
        this.item.push(this.result[i])
      }
      var count=0;
      for(var i=this.item.length-1;i>=0;i--){
        for(var j=1;j<=i;j++){	
          if(parseFloat(this.item[j-1]['profit'])<=parseFloat(this.item[j]['profit'])){
            count=this.item[j];
            this.item[j]=this.item[j-1];
            this.item[j-1]=count;
          }
        }
      }
      this.dataSource = new MatTableDataSource<PeriodicElement>(this.item)
      if(this.result.length==0){
      this.w0title = '没有数据返回'
      this.w0message="警告"
      this.openSnackBar(this.w0title, this.w0message)
      }
    });
  }


  housekeepcontract() {
    this.w0title = '提交清仓...'
    this.w0message="waiting"
    this.openSnackBar(this.w0title, this.w0message)
    let params = {
      "AccountID": this.w0name
    }
    var url = this.url_Production + "Btc/Clear/"
    this.http.post(url, params, { headers: headers }).subscribe(res => {
      this.anyList = res
      console.log(this.anyList)
      this.result = this.anyList.message;
      if(this.result==["success"]){
      this.w0title = '机器人清仓情况'
      this.w0message="成功"
      this.openSnackBar(this.w0title, this.w0message)
      }
      if(this.result==["清仓失败，请联系管理员"]){
        this.w0title = '机器人清仓情况'
        this.w0message="清仓失败，请联系管理员"
        this.openSnackBar(this.w0title, this.w0message)
        }
    });
  }

  clearcontract() {
    this.w0title = '提交平单...'
    this.w0message="waiting"
    this.openSnackBar(this.w0title, this.w0message)
    let params = {
      "symbolID": this.w0symbolID,
      "forceflag": this.w0forceflag,
      "positionSide": this.w0positionSide,
      "percent": this.w0percent,
    }
    var url = this.url_Production + "Btc/ClearContract/"
    this.http.post(url, params, { headers: headers }).subscribe(res => {
      this.anyList = res
      console.log(this.anyList)
      this.result = this.anyList.message;
      if(this.result==["已提交，检查土牛机器人"]){
      this.w0title = '机器人平单情况'
      this.w0message="已提交，检查土牛机器人"
      this.openSnackBar(this.w0title, this.w0message)
      }
      if(this.result==["没有这个单子"]){
        this.w0title = '机器人平单情况'
        this.w0message="没有这个单子"
        this.openSnackBar(this.w0title, this.w0message)
        }
        if(this.result==["目前没有用户开启管理员模式"]){
          this.w0title = '机器人平单情况'
          this.w0message="目前没有用户开启管理员模式"
          this.openSnackBar(this.w0title, this.w0message)
          }
    });
  }
  cancelcontract() {
    this.w0title = '提交撤单...'
    this.w0message="waiting"
    this.openSnackBar(this.w0title, this.w0message)
    let params = {
      "symbolID": this.w0symbolID
    }
    var url = this.url_Production + "Btc/CancelContract/"
    this.http.post(url, params, { headers: headers }).subscribe(res => {
      this.anyList = res
      console.log(this.anyList)
      this.result = this.anyList.message;
      if(this.result==["已提交，检查土牛机器人"]){
      this.w0title = '机器人撤单情况'
      this.w0message="已提交，检查土牛机器人"
      this.openSnackBar(this.w0title, this.w0message)
      }
      if(this.result==["没有这个单子"]){
        this.w0title = '机器人撤单情况'
        this.w0message="没有这个单子"
        this.openSnackBar(this.w0title, this.w0message)
        }
        if(this.result==["目前没有用户开启管理员模式"]){
          this.w0title = '机器人撤单情况'
          this.w0message="目前没有用户开启管理员模式"
          this.openSnackBar(this.w0title, this.w0message)
          }
    });
  }



  ColorClass1(flag){
    let x =flag;
    let className = '';
    if(x==1){
      className = 'RedColor';
    }
    return className
  }
  ColorClass2(flag){
    let x =flag;
    let className = '';
    if(x==2){
      className = 'RedColor';
    }
    return className
  }
  ColorClass3(flag){
    let x =flag;
    let className = '';
    if(x==3){
      className = 'RedColor';
    }
    return className
  }
  ColorClass4(flag){
    let x =flag;
    let className = '';
    if(x==4){
      className = 'RedColor';
    }
    return className
  }
  ColorClass5(flag){
    let x =flag;
    let className = '';
    if(x==5){
      className = 'RedColor';
    }
    return className
  }
  ColorClass6(flag){
    let x =flag;
    let className = '';
    if(x==6){
      className = 'RedColor';
    }
    return className
  }
  ColorClass7(flag){
    let x =flag;
    let className = '';
    if(x==7){
      className = 'RedColor';
    }
    return className
  }
  ColorClass8(flag){
    let x =flag;
    let className = '';
    if(x==8){
      className = 'RedColor';
    }
    return className
  }
  ColorClass9(flag){
    let x =flag;
    let className = '';
    if(x==9){
      className = 'RedColor';
    }
    return className
  }
  ColorClass10(flag){
    let x =flag;
    let className = '';
    if(x==10){
      className = 'RedColor';
    }
    return className
  }
  ColorClass998(flag){
    let x =flag;
    let className = '';
    if(x==998){
      className = 'RedColor'
    }
    return className
  }
  ColorClass997(flag){
    let x =flag;
    let className = '';
    if(x==997){
      className = 'RedColor';
    }
    return className
  }
  ColorClass996(flag){
    let x =flag;
    let className = '';
    if(x==996){
      className = 'RedColor';
    }
    return className
  }


  retrieveItem(){
    var url = this.url_Production + "Btc/Enqselfchoice/"
    this.http.get(url, { headers: headers }).subscribe(res => {
      this.anyList = res
      console.log(this.anyList)
      this.itemList = this.anyList.message;
    });
  }

  retrieveName(){
    var url = this.url_Production + "Btc/EnqName/"
    this.http.get(url, { headers: headers }).subscribe(res => {
      this.anyList = res
      console.log(this.anyList)
      this.nameList = this.anyList.message;
    });
  }




}
