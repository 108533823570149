<form [formGroup]="BossForm" (ngSubmit)="submit(BossForm)">
    <mat-card class="shipping-card">

        <mat-card-header>
            <mat-card-title>超级管理员系统!</mat-card-title>
        </mat-card-header>
        <mat-card-actions>



                <button *ngIf="_parent.flag=='E' ||_parent.flag=='A'"  mat-raised-button color="warn" type="button" (click)="flag=1"
                    [ngClass]="ColorClass1(flag)">机器人查单</button>
                <button *ngIf="_parent.flag=='A'"  mat-raised-button color="warn" type="submit" (click)="flag=2"
                    [ngClass]="ColorClass2(flag)">机器人开单</button>
                <button *ngIf="_parent.flag=='A'" mat-raised-button color="warn" type="button" (click)="flag=3"
                    [ngClass]="ColorClass3(flag)">机器人平单</button>
                <button *ngIf="_parent.flag=='A'" mat-raised-button color="warn" type="button" (click)="flag=4" 
                    [ngClass]="ColorClass4(flag)">机器人撤单</button>
                    <button *ngIf="_parent.flag=='A'" mat-raised-button color="warn" type="button" (click)="flag=6" 
                    [ngClass]="ColorClass6(flag)">机器人清仓</button>
                    <button *ngIf="_parent.flag=='A'" mat-raised-button color="warn" type="button" (click)="flag=7" 
                    [ngClass]="ColorClass7(flag)">机器人创券</button>
                <button *ngIf="_parent.flag=='F'" mat-raised-button color="warn" type="button" (click)="flag=5" 
                [ngClass]="ColorClass4(flag)">智能跟单</button>
                <!-- <button mat-raised-button color="warn" (click)="flag=5" [ngClass]="ColorClass5(flag)"
                    [matMenuTriggerFor]="animals" type="button" disabled>其他</button>
                <a href="http://apige520.77sq.net">
                    <button mat-mini-fab color="warn" type="button">
                        <mat-icon aria-label="Example icon-button with a heart icon" >神</mat-icon>
                    </button>
                    </a>
                <mat-menu #Enquiry="matMenu">
                    <button mat-menu-item type="submit" (click)="flag=6" [ngClass]="ColorClass6(flag)" disabled>短信模板</button>
                    <button mat-menu-item type="submit" (click)="flag=7" [ngClass]="ColorClass7(flag)" disabled>用户信息</button>
                </mat-menu>
                <mat-menu #animals="matMenu">
                    <button mat-menu-item type="submit" (click)="flag=8" [ngClass]="ColorClass6(flag)" disabled>待定</button>
                    <button mat-menu-item type="submit" (click)="flag=9" [ngClass]="ColorClass7(flag)" disabled>待定</button>
                </mat-menu> -->

            <!---->




        </mat-card-actions>
        <mat-card-content >
          <div *ngIf="flag==1||flag==6" class="row">
            <div class="row">
            <div class="col">
              <mat-form-field>
              <mat-select formControlName="name" matInput placeholder="用户名字" >
                <mat-option>None</mat-option>
                <ng-container *ngFor="let i of nameList">
                  <mat-option value={{i.name}}>{{i.name}}</mat-option>
                </ng-container>
              </mat-select>
              </mat-form-field>     
            </div>
          </div>
        </div>

            <div *ngIf="flag==2||flag==3||flag==4" class="row">
              <div class="row">
              <div class="col">
                <mat-form-field>
                <mat-select formControlName="symbolID" matInput placeholder="币种名字" >
                  <mat-option>None</mat-option>
                  <ng-container *ngFor="let i of itemList">
                    <mat-option value={{i.symbolID}}>{{i.symbolID}}</mat-option>
                  </ng-container>
                </mat-select>
                </mat-form-field>     
              </div>
            </div>
          </div>

          <div *ngIf="flag==2" class="row">
            <div class="row">
              <div class="col">
                <mat-form-field>
                <mat-select formControlName="direction" matInput placeholder="多空方向" >
                  <mat-option>None</mat-option>
                  <ng-container *ngFor="let i of directionlist">
                    <mat-option value={{i.value}}>{{i.direction}}</mat-option>
                  </ng-container>
                </mat-select>
                </mat-form-field>     
              </div>
            </div>
            <div class="row">
              <div class="col">
                <mat-form-field class="full-width">
                  <input matInput placeholder="开单价格" formControlName="price">
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <mat-form-field class="full-width">
                  <input matInput placeholder="止盈价格" formControlName="profitprice">
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <mat-form-field class="full-width">
                  <input matInput placeholder="止损价格" formControlName="stopprice">
                </mat-form-field>
              </div>
            </div>

            </div>
          
          
            <div *ngIf="flag==3" class="row">
              <div class="row">
                <div class="col">
                  <mat-form-field>
                  <mat-select formControlName="forceflag" matInput placeholder="平仓收益率检查" >
                    <mat-option>None</mat-option>
                    <ng-container *ngFor="let i of forcelist">
                      <mat-option value={{i.value}}>{{i.force}}</mat-option>
                    </ng-container>
                  </mat-select>
                  </mat-form-field>     
                </div>
              </div>
              </div>

              <div *ngIf="flag==3" class="row">
                <div class="row">
                  <div class="col">
                    <mat-form-field>
                    <mat-select formControlName="positionSide" matInput placeholder="多空方向选择" >
                      <mat-option>None</mat-option>
                      <ng-container *ngFor="let i of positionlist">
                        <mat-option value={{i.value}}>{{i.position}}</mat-option>
                      </ng-container>
                    </mat-select>
                    </mat-form-field>     
                  </div>
                </div>
                </div>

                <div *ngIf="flag==3" class="row">
                  <div class="row">
                    <div class="col">
                      <mat-form-field>
                      <mat-select formControlName="percent" matInput placeholder="仓位百分比" >
                        <mat-option>None</mat-option>
                        <ng-container *ngFor="let i of percentlist">
                          <mat-option value={{i.value}}>{{i.percent}}</mat-option>
                        </ng-container>
                      </mat-select>
                      </mat-form-field>     
                    </div>
                  </div>
                  </div>


                  <div *ngIf="flag==5" class="row">

                    <div class="row" style="margin-right: 30px">
                      <div class="col">
                        <mat-form-field class="full-width">
                          <input matInput placeholder="带单Access_Key" formControlName="Access_Key">
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row" style="margin-right: 30px">
                      <div class="col">
                        <mat-form-field class="full-width">
                          <input matInput placeholder="带单Secret_Key" formControlName="Secret_Key">
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <mat-form-field class="full-width">
                          <input matInput placeholder="带单Passphrase" formControlName="Passphrase">
                        </mat-form-field>
                      </div>
                    </div>
        
                    </div>     
                    <div *ngIf="flag==5" class="row">

                      <div class="row" style="margin-right: 30px">
                        <div class="col">
                          <mat-form-field class="full-width">
                            <input matInput placeholder="跟单Access_Key" formControlName="Access_Key_">
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="row" style="margin-right: 30px">
                        <div class="col">
                          <mat-form-field class="full-width">
                            <input matInput placeholder="跟单Secret_Key" formControlName="Secret_Key_">
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <mat-form-field class="full-width">
                            <input matInput placeholder="跟单Passphrase" formControlName="Passphrase_">
                          </mat-form-field>
                        </div>
                      </div>
          
                      </div>         
                      <div *ngIf="flag==5" class="row">

                        <div class="row">
                          <div class="col">
                            <mat-form-field class="full-width">
                              <input matInput placeholder="百分比(%)" formControlName="follow_percent">
                            </mat-form-field>
                          </div>
                        </div>            
                        </div>  
                        <div *ngIf="flag==7" class="row">
                          <div class="row">
                            <div class="col">
                              <mat-form-field class="full-width">
                                <input matInput placeholder="券面值" formControlName="ticket_value">
                              </mat-form-field>
                            </div>
                          </div>
                          <div class="row" style="padding-left: 3%;">
                            <div class="col">
                              <mat-form-field class="full-width">
                                <input matInput placeholder="券数量" formControlName="ticket_count">
                              </mat-form-field>
                            </div>
                          </div>              
                          </div>   
            <!-- <div class="row" *ngIf="flag==1" >
              <div class="col">
                <mat-form-field class="full-width">
                  <input matInput placeholder="密码"  formControlName="password">
                </mat-form-field>
              </div>
            </div>     
            <div class="row" *ngIf="flag==3" >
                <div class="col">
                  <mat-form-field class="full-width">
                    <input matInput placeholder="Access_Key"  formControlName="access_key">
                  </mat-form-field>
                </div>
              </div>    
              <div class="row" *ngIf="flag==3" >
                <div class="col">
                  <mat-form-field class="full-width">
                    <input matInput placeholder="Secret_Key"  formControlName="secret_key">
                  </mat-form-field>
                </div>
              </div>   
              <div class="row" *ngIf="flag==3" >
                <div class="col">
                  <mat-form-field class="full-width">
                    <input matInput placeholder="昵称"  formControlName="name">
                  </mat-form-field>
                </div>
              </div>   -->
      
          </mat-card-content>
          <mat-card-actions *ngIf="flag==1">
            <button mat-raised-button color="primary" type="submit" (click)="submitFlag=88" [disabled]="BossForm.invalid">查单 </button>
          </mat-card-actions>
          <mat-card-actions *ngIf="flag==2">
            <button mat-raised-button color="primary" type="submit" (click)="submitFlag=2" [disabled]="BossForm.invalid">开单 </button>
          </mat-card-actions>
          <mat-card-actions *ngIf="flag==3">
            <button mat-raised-button color="primary" type="submit" (click)="submitFlag=3" [disabled]="BossForm.invalid">平单 </button>
          </mat-card-actions>
          <mat-card-actions *ngIf="flag==4">
            <button mat-raised-button color="primary" type="submit" (click)="submitFlag=4" [disabled]="BossForm.invalid">撤单 </button>
          </mat-card-actions>
          <mat-card-actions *ngIf="flag==5">
            <button mat-raised-button color="primary" type="submit" (click)="submitFlag=5" [disabled]="BossForm.invalid">跟单 </button>
          </mat-card-actions>
          <mat-card-actions *ngIf="flag==6">
            <button mat-raised-button color="primary" type="submit" (click)="submitFlag=6" [disabled]="BossForm.invalid">清仓 </button>
          </mat-card-actions>
          <mat-card-actions *ngIf="flag==7">
            <button mat-raised-button color="primary" type="submit" (click)="submitFlag=7" [disabled]="BossForm.invalid">创券 </button>
          </mat-card-actions>
    </mat-card>
    <mat-divider></mat-divider>

    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" class="full-width-table" matSort aria-label="Elements">
        <!-- Id Column -->
        <ng-container matColumnDef="symbol">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="expr-table-header">币种<mat-icon
              svgIcon="coin"></mat-icon>
          </th>
          <td mat-cell *matCellDef="let row" class="normalCust">{{row.symbol}}</td>
        </ng-container>
  

        <!-- Name Column -->
        <ng-container matColumnDef="direction">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="expr-table-header">开单方向<mat-icon svgIcon="direction">
            </mat-icon>
          </th>
          <td mat-cell *matCellDef="let row" class="normalCust">{{row.direction}}</td>
        </ng-container>


  
        <!-- Name Column -->
        <ng-container matColumnDef="entryPrice">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="expr-table-header">开单价格<mat-icon svgIcon="open">
            </mat-icon>
          </th>
          <td mat-cell *matCellDef="let row" class="normalCust">{{row.entryPrice}}</td>
        </ng-container>
  
        <ng-container matColumnDef="markPrice">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="expr-table-header">市场价格<mat-icon svgIcon="marketing">
            </mat-icon>
          </th>
          <td mat-cell *matCellDef="let row" class="normalCust">{{row.markPrice}}</td>
        </ng-container>
        <ng-container matColumnDef="overPrice">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="expr-table-header">爆仓价格<mat-icon svgIcon="over">
            </mat-icon>
          </th>
          <td mat-cell *matCellDef="let row" class="normalCust">{{row.overPrice}}</td>
        </ng-container>
  
        <ng-container matColumnDef="scale">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="expr-table-header">持仓占比<mat-icon svgIcon="percent">
            </mat-icon>
          </th>
          <td mat-cell *matCellDef="let row" class="normalCust">{{row.scale}}</td>
        </ng-container>

        <ng-container matColumnDef="remaincount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="expr-table-header">可开单数量<mat-icon svgIcon="count">
            </mat-icon>
          </th>
          <td mat-cell *matCellDef="let row" class="normalCust">{{row.remaincount}}</td>
        </ng-container>


        <!-- Name Column -->
        <ng-container matColumnDef="profit">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="expr-table-header">收益率<mat-icon svgIcon="profit">
            </mat-icon>
          </th>
          <td mat-cell *matCellDef="let row" class="normalCust">{{row.profit}}</td>
        </ng-container>
  
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <!--<div class ="spinner-container" *ngif ="loading$ | async" >
            <mat-spinner></mat-spinner>
          </div>  -->
      <mat-paginator [length]="custTotal" [pageIndex]="0" [pageSize]="20" [pageSizeOptions]="[10, 20, 50]"
        showFirstLastButtons> </mat-paginator>
    </div>


</form>