<mat-toolbar>   
    <img class="logo" src="../assets/logo.jpg" alt="LOGO" width="60" height="60" />
    <span class="fill-remaining-space"></span>
    <span>
        <div class="title">啊皮哥</div>
    
    </span>
    <span class="fill-remaining-space"></span>
<img class="logo" src="../assets/logoB.jpg " alt="LOGO" width="60" height="60" />
</mat-toolbar>

	 
    
<!-- <app-menu></app-menu> -->
<router-outlet ></router-outlet>
<div style="width:300px;margin:0 auto; padding:20px 0;">
    <a target="_blank" href="https://beian.miit.gov.cn/" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
    <!-- <img src="请将备案图标链接放置此处" style="float:left;"/> -->
    <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">
        鄂ICP备2024034185号-1</p></a>
    </div>	